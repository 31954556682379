import { match } from 'ts-pattern';

import { EnumsProgramType } from '@lp-lib/api-service-client/public';

import { getStaticAssetPath } from '../../../../src/utils/assets';
import { type ProgramDetailsProps } from './types';

function OthersSayingCard(props: {
  avatarUrl: string;
  name: string;
  position: string;
  message: React.ReactNode;
  companyLogoUrl: string;
  companyLogoSize?: string;
}) {
  return (
    <div className='w-65 rounded-2.5xl bg-main-layer py-5 flex flex-col justify-between gap-3'>
      <div className='flex-none px-5 flex items-center gap-2'>
        <img
          src={props.avatarUrl}
          alt=''
          className='w-12.5 h-12.5 rounded-full'
          loading='lazy'
        />
        <div>
          <div className='text-sms font-bold font-Montserrat'>{props.name}</div>
          <div className='text-3xs max-w-36 font-Montserrat text-icon-gray leading-3'>
            {props.position}
          </div>
        </div>
      </div>
      <div className='flex-1 px-5 text-sms leading-5'>{props.message}</div>
      <div className='mt-3 w-full h-9 flex justify-center items-center'>
        <img
          src={props.companyLogoUrl}
          alt=''
          className={`${props.companyLogoSize ?? 'w-30 h-9'} object-contain`}
          loading='lazy'
        />
      </div>
    </div>
  );
}

const Celebrations = (
  <>
    <OthersSayingCard
      avatarUrl={getStaticAssetPath('images/what-customers-say/anthony-vu.png')}
      name='Anthony Vu'
      position='Director Of Operations'
      message={
        <>
          Birthday & Anniversary Celebrations ensures no special day goes
          unnoticed. We love the custom messages and the GIFs add a wonderful
          personal touch that everyone appreciates.
        </>
      }
      companyLogoUrl={getStaticAssetPath('images/what-customers-say/oklo.png')}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/olga-spivak.png'
      )}
      name='Olga Spivak'
      position='Vice President of People'
      message={
        <>
          Our team feels more valued with Birthday & Anniversary Celebrations.
          It’s a simple way to recognize and celebrate each other’s milestones.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/ycharts.png'
      )}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/alexis-shelvy.png'
      )}
      name='Alexis Shelvy'
      position='Executive Assistant'
      message={
        <>
          Celebrating birthdays and anniversaries has become seamless with this
          tool. It’s saved us a ton of time and it’s a great way to make our
          team members feel appreciated.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/terminal.png'
      )}
    />
  </>
);

const Intros = (
  <>
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/matteo-latini.png'
      )}
      name='Matteo Latini'
      position='Partner and Chief People Officer'
      message={
        <>
          Intros has been invaluable for fostering genuine connections within
          our team. The icebreakers on Luna Park are fun and have really helped
          build trust.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/nebulab.png'
      )}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/ana-castillo.png'
      )}
      name='Ana Castillo'
      position='People Operations Specialist'
      message={
        <>
          With Intros, our team members are getting to know each other better,
          regardless of location without a lot of effort from us. It's a
          fantastic tool for building relationships.
        </>
      }
      companyLogoUrl={getStaticAssetPath('images/what-customers-say/arize.png')}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath('images/what-customers-say/jen-paxton.png')}
      name='Jen Paxton'
      position='Senior Director of People and Talent'
      message={
        <>
          The automated team groupings and icebreakers from Intros have truly
          enhanced our team’s cohesion. We’ve seen stronger bonds and better
          collaboration.
        </>
      }
      companyLogoUrl={getStaticAssetPath('images/what-customers-say/roofr.png')}
    />
  </>
);

const Pairing = (
  <>
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/shaquaya-perdue.png'
      )}
      name='Shaquaya Perdue'
      position='Talent Experience Partner'
      message={
        <>
          Global Pairing Tournaments have made it exciting for us to instill a
          sense of community in our team collaboration. The challenges are fun
          and foster great connections.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/crowdstreet.png'
      )}
      companyLogoSize='w-34 h-5.5'
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath('images/what-customers-say/dustin-low.png')}
      name='Dustin Low'
      position='VP, People'
      message={
        <>
          For team members that don’t like the stress and anxiety of Donut, the
          Global Pairing tournament has proven to be a perfect antidote!
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/output.png'
      )}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/teddy-davidson.png'
      )}
      name='Teddy Davidson'
      position='Senior Manager, People Ops'
      message={
        <>
          The collaborative challenges and global leaderboard in Global Pairing
          Tournaments have been a hit with our team. It’s a fantastic tool for
          fostering engagement.
        </>
      }
      companyLogoUrl={getStaticAssetPath('images/what-customers-say/hone.png')}
    />
  </>
);

const Recognition = (
  <>
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/suf-baileche.png'
      )}
      name='Suf Baileche'
      position='Head of People & Talent'
      message={
        <>
          Ice Cream Shoutouts adds a playful and engaging way to show gratitude.
          The dynamic leaderboard is a fun motivator for our team.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/verify-my.png'
      )}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/lida-morgado.png'
      )}
      name='Lida Morgado'
      position='HR Manager'
      message={
        <>
          Our team has embraced Ice Cream Shoutouts wholeheartedly. It's an
          enjoyable way to celebrate each other’s efforts and foster a positive
          work environment.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/real-geeks.png'
      )}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath('images/what-customers-say/sean-smit.png')}
      name='Sean Smit'
      position='Senior People Care Partner'
      message={
        <>
          Giving and receiving Ice Cream Shoutouts has become a highlight for
          our team. It’s an excellent way to recognize contributions and build
          morale.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/superside.png'
      )}
    />
  </>
);

const WaterCoolers = (
  <>
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/jessica-warren.png'
      )}
      name='Jessica Warren'
      position='Head of People'
      message={
        <>
          Since installing Water Coolers, I, as well as many others, have
          started chatting and building relationships with people I barely know
          and work with! I never knew I had so much in common with some of our
          team! It's been a great addition to the suite of engagement products
          that LP offers!
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/chowly.png'
      )}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath('images/what-customers-say/titus-yao.png')}
      name='Titus Yao'
      position='Head of HR'
      message={
        <>
          Our culture has benefited greatly from the flexibility that the Water
          Cooler Convos Slack channel provides. Team members are able to build
          stronger ties through engaging in conversation. It is a great way to
          humanize working in a distributed environment that lacks the ability
          to have random in-person bump-ins.
        </>
      }
      companyLogoUrl={getStaticAssetPath('images/what-customers-say/orum.png')}
    />
    <OthersSayingCard
      avatarUrl={getStaticAssetPath(
        'images/what-customers-say/jessica-d-aleo.png'
      )}
      name={`Jessica D'Aleo`}
      position='Business Operations Manager'
      message={
        <>
          I love our Water Cooler Convos Slack channel. I learn new things about
          coworkers each day and am exposed to different perspectives.
        </>
      }
      companyLogoUrl={getStaticAssetPath(
        'images/what-customers-say/consensys.png'
      )}
    />
  </>
);

export function ProgramsDetailsWhatCustomersSay(props: ProgramDetailsProps) {
  const { program } = props;

  const content = match(program.type)
    .with(
      EnumsProgramType.ProgramTypeBirthdayAndCelebrations,
      () => Celebrations
    )
    .with(EnumsProgramType.ProgramTypeIntros, () => Intros)
    .with(EnumsProgramType.ProgramTypeGlobalPairing, () => Pairing)
    .with(EnumsProgramType.ProgramTypeRecognition, () => Recognition)
    .with(EnumsProgramType.ProgramTypeWaterCooler, () => WaterCoolers)
    .otherwise(() => null);

  if (!content) return null;
  return (
    <section className='w-full'>
      <div className='text-3.5xl font-bold'>What Our Customers Say</div>
      <div className='mt-7 flex gap-2.5'>{content}</div>
    </section>
  );
}
