export function SlackIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className || 'w-full h-full'}
      viewBox='0 0 122.8 122.8'
    >
      <path
        fill='#e01e5a'
        d='M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z'
      ></path>
      <path
        fill='#36c5f0'
        d='M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z'
      ></path>
      <path
        fill='#2eb67d'
        d='M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z'
      ></path>
      <path
        fill='#ecb22e'
        d='M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z'
      ></path>
    </svg>
  );
}

export function SlackIcon2(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      className={props.className || 'w-full h-full fill-current'}
    >
      <path
        fillRule='evenodd'
        d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24ZM16.5355 27.3968C16.5355 28.8855 15.3194 30.1016 13.8306 30.1016C12.3419 30.1016 11.1258 28.8855 11.1258 27.3968C11.1258 25.9081 12.3419 24.6919 13.8306 24.6919H16.5355V27.3968ZM20.6032 24.6919C19.1145 24.6919 17.8984 25.9081 17.8984 27.3968V34.1694C17.8984 35.6581 19.1145 36.8742 20.6032 36.8742C22.0919 36.8742 23.3081 35.6581 23.3081 34.1694V27.3968C23.3081 25.9081 22.0919 24.6919 20.6032 24.6919ZM20.6032 16.5355C19.1145 16.5355 17.8984 15.3194 17.8984 13.8306C17.8984 12.3419 19.1145 11.1258 20.6032 11.1258C22.0919 11.1258 23.3081 12.3419 23.3081 13.8306V16.5355H20.6032ZM23.3081 20.6032C23.3081 19.1145 22.0919 17.8984 20.6032 17.8984H13.8306C12.3419 17.8984 11.1258 19.1145 11.1258 20.6032C11.1258 22.0919 12.3419 23.3081 13.8306 23.3081H20.6032C22.0919 23.3081 23.3081 22.0919 23.3081 20.6032ZM31.4645 20.6032C31.4645 19.1145 32.6806 17.8984 34.1694 17.8984C35.6581 17.8984 36.8742 19.1145 36.8742 20.6032C36.8742 22.0919 35.6581 23.3081 34.1694 23.3081H31.4645V20.6032ZM27.3968 23.3081C28.8855 23.3081 30.1016 22.0919 30.1016 20.6032V13.8306C30.1016 12.3419 28.8855 11.1258 27.3968 11.1258C25.9081 11.1258 24.6919 12.3419 24.6919 13.8306V20.6032C24.6919 22.0919 25.9081 23.3081 27.3968 23.3081ZM27.3968 31.4645C28.8855 31.4645 30.1016 32.6806 30.1016 34.1694C30.1016 35.6581 28.8855 36.8742 27.3968 36.8742C25.9081 36.8742 24.6919 35.6581 24.6919 34.1694V31.4645H27.3968ZM24.6919 27.3968C24.6919 28.8855 25.9081 30.1016 27.3968 30.1016H34.1694C35.6581 30.1016 36.8742 28.8855 36.8742 27.3968C36.8742 25.9081 35.6581 24.6919 34.1694 24.6919H27.3968C25.9081 24.6919 24.6919 25.9081 24.6919 27.3968Z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
